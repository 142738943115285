import styled from "styled-components";
import { mediaQuery } from "./base";

const Avatar = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 25px;
  margin: 10px;
`;

const Icon = styled.img`
  height: 23px;
  width: 23px;
  margin-right: 10px;
`;

const MasterContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  width: 100%;
  top: 3px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: Georgia, "Times New Roman", Times, serif;
  width: 94%;
`;

interface DropDownProps {
  display: boolean;
}

const DropDownContent = styled.div<DropDownProps>`
  display: ${(props) => (props.display ? "block" : "none")};
  position: absolute;
  right: 20%;
  background-color: #f9f9f9;
  border-radius: 4px;
  width: 300px;
  max-height: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 11;
  padding-bottom: 9px;
  overflow-y: auto;
  @media (max-width: ${mediaQuery.phone}) {
    right: -150%;
  }
`;

const Notification_Header = styled.div`
  font-size: 17px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 600;
  line-height: 17.5px;
  margin-top: 4px;
  margin-bottom: 19px;
`;

const Notification_Body = styled.div`
  font-size: 11px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 100;
  line-height: 17.5px;
  width: 60%;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const Notification_Image = styled.img`
  height: 34px;
  width: 34px;
  border-radius: 17px;
  margin-right: 10px;
`;

const Badge = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: orange;
  position: absolute;
  font-size: 11px;
  color: white;
  top: -10px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
`;

const LastUpdated = styled.div`
  font-size: 10px;
  font-weight: 100;
  color: grey;
  margin-left: 2.75%;
  margin-bottom: 3.5%;
  @media (max-width: 600px) {
    font-size: 8px;
  }
`;

export { Notification_Header, LastUpdated, Notification_Body, FlexCenter, Notification_Image, FlexDiv, Badge, DropDownContent, Avatar, MasterContainer, Container, Icon };
