import styled from "styled-components";

const Header = styled.div`
  font-size: 43px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 600;
  color: white;
  text-align: center;
  line-height: 50px;
  @media (max-width: 600px) {
    font-size: 30px;
  }
`;

const SubTitle = styled.div`
  font-size: 25px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 500;
  color: white;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
  line-height: 62px;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
const TinyTitle = styled.div`
  font-size: 10.5px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 500;
  color: white;
  text-align: center;
  line-height: 25px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #fff;
  border-radius: 12.5px;
  padding: 7.5px;
  margin-top: 15px;
  margin-bottom: 39px;
  @media (max-width: 600px) {
  }
`;

const Other_Header = styled.div`
  font-size: 30px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 600;
  text-align: center;
  color: #494949;
  margin-bottom: 20px;
`;

const HeroCaption = styled.div`
  font-size: 10px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 500;
  color: white;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const Card = styled.div`
  border-radius: 5px;
  height: 300px;
  padding: 15px;
  border-width: 1px;
  border-color: gray;
  border-style: solid;
  margin-top: 50px;
  margin-bottom: 50px;
  border-color: rgb(231, 231, 231);
  background-color: rgb(231, 231, 231);
`;

const Caption = styled.div`
  font-size: 12px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 200;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 20px;
`;

const Bullet = styled.div`
  line-height: 29px;
  margin-bottom: 15px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 300;
  color: #494949;
  text-align: center;
`;

const SmallCaption = styled.div`
  line-height: 29px;
  margin-bottom: 25px;
  font-size: 9.25px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 300;
  color: #6e6e6e;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
`;

const Version = styled.div`
  font-size: 14px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 500;
  color: white;
  text-align: center;
  line-height: 25px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #fff;
  border-radius: 12.5px;
  padding: 7.5px;
  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

export { Bullet, Version, TinyTitle, SmallCaption, Header, SubTitle, HeroCaption, Card, Other_Header, Caption };
