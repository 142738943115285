import React, { FC, ReactElement } from "react";
import styled from "styled-components";

type IProps = {
  title: string;
  has_graphic: boolean;
  has_emoji?: boolean;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border-width: 0px;
  border-bottom-width: 10px;
  border-style: solid;
  background: rgb(138, 43, 226);
  background: radial-gradient(circle, rgba(156, 121, 190, 1) 24%, rgba(173, 130, 120, 1) 78%);
  border-color: rgb(231, 231, 231);
`;

const TitleContainer = styled.div`
  margin-right: 10px;
  @media (max-width: 600px) {
  }
`;

const Header = styled.div`
  font-size: 30px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 600;
  color: white;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 21px;
    margin-top: 25px;
  }
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;
  @media (max-width: 600px) {
    height: 28px;
    width: 28px;
    margin-top: 25px;
  }
`;

const Cover: FC<IProps> = ({ title, has_graphic, has_emoji }): ReactElement => {
  return (
    <Container>
      <TitleContainer>
        <Header>
          {title}
          {has_emoji && " 😺"}
        </Header>
      </TitleContainer>
      {has_graphic && <Icon src="https://cdn-icons-png.flaticon.com/512/9357/9357543.png" />}
    </Container>
  );
};

export default Cover;
