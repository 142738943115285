import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import { mediaQuery } from "../styles/base";

const Container = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  border-style: solid;
  border-color: #75ae96;
  border-width: 0px;
  border-top-width: 2.5px;
  /* border-bottom-width: 2.5px; */
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
  @media (max-width: ${mediaQuery.tablet}) {
    height: 100px;
  }
`;

const Item = styled.div`
  width: 10%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 25.333%;
  }
`;

const Stat = styled.div`
  font-size: 18px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 600;
  color: #494949;
  text-align: center;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: 15px;
  }
`;

const Caption = styled.div`
  font-size: 13px;
  font-family: "Roboto Serif", sans-serif;
  color: #494949;
  text-align: center;
  line-height: 24px;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: 11px;
  }
`;

const Stats: FC = (): ReactElement => {
  return (
    <Container>
      <Item>
        <Stat>2,349</Stat>
        <Caption>users</Caption>
      </Item>
      <Item>
        <Stat>29,300</Stat>
        <Caption>XP Points awarded</Caption>
      </Item>
    </Container>
  );
};

export default Stats;
