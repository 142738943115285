import React from "react";
import Env from "./config";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import Router from "./start/_Router";

const client = new ApolloClient({
  uri: Env.server,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  );
}

export default App;
