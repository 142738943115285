import { gql } from "@apollo/client";

export const SAVE_DRAFT = gql`
  mutation saveDraft($id: Int, $drafts_code: String, $body: String!, $source: String, $code_snippet: String, $source_text: String) {
    saveDraft(id: $id, drafts_code: $drafts_code, body: $body, source: $source, code_snippet: $code_snippet, source_text: $source_text) {
      id
      body
      code_snippet
      source
      source_text
    }
  }
`;
