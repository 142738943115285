import styled from "styled-components";

const mediaQuery = {
  // desktop: "1200px",
  tablet: "1024px",
  phone: "480px",
};

interface TagProps {
  selected: boolean;
}

interface QuestionProps {
  smaller: boolean;
}

interface MasterDivProps {
  width: number;
}

interface SpacerProps {
  height?: string;
}

interface CoverProps {
  url?: string;
}

const MasterContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  width: 100%;
  top: 3px;
`;

const MasterDiv = styled.div<MasterDivProps>`
  width: ${(props) => props.width}%;
  margin: 5px;
  padding: 35px;
  padding-right: 28px;
  overflow-y: auto;
  border-radius: 5px;
  margin-top: 1%;
  background-color: white;
  margin-bottom: 10%;
  @media (max-width: 900px) {
    width: 75%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: Georgia, "Times New Roman", Times, serif;
  width: 94%;
`;

const Cover = styled.div<CoverProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border-width: 0px;
  border-bottom-width: 10px;
  border-style: solid;
  background-color: rgb(138, 43, 226);
  border-color: rgb(231, 231, 231);
  /* background: rgb(138, 43, 226);
  background: linear-gradient(90deg, rgba(138, 43, 226, 1) 35%, rgba(136, 153, 185, 1) 100%); */
`;

const Card = styled.div`
  border-radius: 5px;
  height: 300px;
  padding: 15px;
  border-width: 1px;
  border-color: gray;
  border-style: solid;
  margin-top: 50px;
  margin-bottom: 50px;
  border-color: rgb(231, 231, 231);
  background-color: rgb(231, 231, 231);
`;

const Avatar = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 25px;
`;

const Spacer = styled.div<SpacerProps>`
  height: ${(props) => (!props.height ? "30px" : props.height)};
`;

// SMALLER UI

const Question = styled.div<QuestionProps>`
  font-size: ${(props) => (!props.smaller ? "15px" : "13px")};
  line-height: 20px;
  // font-size: 14;
  /* font-family: "Roboto Serif", sans-serif; */
  color: gray;
  font-weight: 300;
`;

const Tip = styled.div`
  font-size: 10px;
  /* font-family: "Roboto Serif", sans-serif; */
  color: gray;
  font-weight: 300;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const Tag = styled.div<TagProps>`
  border-radius: 5px;
  background-color: ${(props) => (props.selected ? "#ECFFA6" : "aliceblue")};
  padding: 10px;
  /* font-family: "Roboto Serif", sans-serif; */
  color: gray;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 10px;
  margin-right: 8px;
  min-width: 30px;
  font-size: 11px;
`;

const MiniTag = styled.div<TagProps>`
  border-radius: 5px;
  background-color: ${(props) => (props.selected ? "#ECFFA6" : "aliceblue")};
  padding: 8px;
  /* font-family: "Roboto Serif", sans-serif; */
  color: gray;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  margin-right: 8px;
`;

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5, 5+",
  },
];

const ErrorDiv = styled.div`
  margin-top: 10px;
  padding: 15px;
  border-radius: 4px;
  font-size: 15px;
  line-height: 23px;
  margin-bottom: 12px;
  font-family: "Roboto Serif", sans-serif;
  color: white;
  font-weight: 100;
  background-color: red;
`;

const CharCount = styled.div`
  font-size: 10px;
  margin-bottom: 20px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 100;
  margin-top: 4px;
  margin-left: 4px;
`;

interface StatProps {
  avatar_width: number;
}

const Stat = styled.div<StatProps>`
  background-color: white;
  position: relative;
  height: 13px;
  width: 13px;
  border-radius: 6.5px;
  top: -16px;
  right: ${(props) => (props.avatar_width * -0.72).toString() + "px"};
  z-index: 1000;
  font-size: 7px;
  padding-top: 2.5px;
  text-align: center;
  color: rgb(138, 43, 226);
  @media (max-width: 600px) {
    right: ${(props) => (props.avatar_width * -0.65).toString() + "px"};
  }
`;

export { Stat, mediaQuery, MasterContainer, MasterDiv, Tip, Container, Avatar, Cover, Card, Question, TagContainer, Tag, MiniTag, marks, Spacer, ErrorDiv, CharCount };
