const prod = true;

const prod_server = "https://thelearningforce-server-e177161fdcbd.herokuapp.com/graphql";
const dev_server = "http://localhost:4000/graphql";

const export_env_urls = () => {
  if (prod) {
    const env = {
      server: prod_server,
      env: prod,
    };
    return env;
  } else {
    const env = {
      server: dev_server,
      env: prod,
    };
    return env;
  }
};

export default export_env_urls();
