import { createTheme } from "@mui/material/styles";
import styled from "styled-components";

const Cover = styled.div`
  height: 135px;
`;

const theme = createTheme({
  typography: {
    // fontFamily: `"Roboto Serif"`,
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    bottom: "auto",
    marginRight: "-35%",
    transform: "translate(-50%, -50%)",
    borderRadius: 5,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: "#919191",
    padding: 0,
  },
  overlay: { zIndex: 1000 },
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const MasterDiv = styled.div`
  width: 70%;
  margin: 5px;
  padding: 5px;
  overflow-y: auto;
`;

const Header = styled.div`
  font-size: 20px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 600;
  text-align: center;
`;

const Caption = styled.div`
  font-size: 14px;
  font-family: "Roboto Serif", sans-serif;
  text-align: center;
`;

const Submit = styled.input`
  border-radius: 7.5px;
  border-width: 0px;
  padding: 10px;
  cursor: pointer;
`;

const Subtitle = styled.div`
  padding: 18px;
  border-radius: 12px;
  border-style: dashed;
  border-width: 1px;
  border-color: #9595d2;
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 28px;
  margin-top: 10px;
  /* font-family: "Roboto Serif", sans-serif; */
  color: #000000;
  font-weight: 300;
  background-color: #00000014;
`;

const X = styled.div`
  color: "gray";
  text-align: right;
  font-size: 15px;
  padding: 12px;
  padding-bottom: 0px;
  cursor: pointer;
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const CheckBoxText = styled.div`
  font-size: 12px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.6);
`;

// color: "gray", textAlign: "right", fontSize: 15, padding: 12, paddingBottom: 0, cursor: "pointer"
export { Cover, Caption, FlexCenter, CheckBoxText, theme, customStyles, Container, MasterDiv, Header, Submit, Subtitle, X };
